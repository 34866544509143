
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SvgIcon from '@/components/utilities/SvgIcon.vue';
/* DIRECTIVES */
import {VMoney} from 'v-money'
import { Rate } from '@/modules/rates/interfaces/rate.interface';
import { currencyConvertion, reverseCurrencyConvertion } from '@/modules/rates/functions/rates.functions';
import { CountryCurrency } from '@/modules/countries/interfaces/countries.interface';
/* FUNCTIONS */
import {formattCurrency} from '@/utils/currency.functions'
import { keySort } from '@/utils/sort-functions';
@Component({
    components: {
        SvgIcon
    },
    directives:{
        money: VMoney
    }
})
export default class Calculator extends Vue {
    @Prop() change?: boolean;
    @Prop() label? : {response: boolean, origin: string, destiny: string, small?: boolean,currency?: boolean};
    @Prop() origin!:{country: string,
                    currency: string,
                    countryCurrency?: CountryCurrency,
                    disabledInput: boolean,
                    disabledDrowdown:boolean,
                    rightDropdown?: boolean}
    @Prop() destiny!:{country: string,
                    currency: string,
                    countryCurrency?: CountryCurrency,
                    disabledInput: boolean,
                    disabledDrowdown:boolean,
                    rightDropdown?: boolean}
    @Prop() originAmountParent!: number;
    @Prop() destinyAmountParent!: number;
    @Prop() rate!: Rate;
    @Prop() total!: number;
    @Prop() align?: string;
    @Prop() visibility?: boolean;
    @Prop() cols?: string;
    @Prop() sm?: string;
    @Prop() md?: string;
    @Prop() lg?: string;
    @Prop() xl?: string;
    @Prop() destinyCountriesCurrencies?: CountryCurrency[]
    @Prop() originCountriesCurrencies?: CountryCurrency[]
    @Prop() small?: boolean
    @Prop() writingInOriginValGlobal?: boolean;
    @Prop() boxClasses?: string;
    originAmmount: number = 0;
    destinyAmmount: number= 0;
    formattedCurrency={
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
         precision: 2,
          masked: true 
    }
    localRate: Rate = {operation: null,rate_factor:0.00}
    phone : any = ''
    writingInOriginVal = true

    mounted(){
        this.localRate = this.rate
        if (this.originAmountParent >0 && this.destinyAmountParent>0){
            this.writingInOriginVal = null;
            this.originAmmount = this.originAmountParent;
            this.destinyAmmount = this.destinyAmountParent;
        }
        this.writingInOriginVal = this.writingInOriginValGlobal
    }

    @Watch('originAmountParent')
    updateOriginAmountParent(newVal: number){
        this.originAmmount = newVal;
    }

    // @Watch('destinyAmountParent')
    updateDestinyAmountParent(newVal: number){
         this.destinyAmmount = newVal;
    }

    get foriginAmmount() {
        //   return this.formatInput(this.originAmmount);
        return formattCurrency(this.originAmmount)
    }

    get filteredOriginCountriesCurrencies(){
        if (process.env.VUE_APP_EUROPE_VERSION){
            const europe = this.originCountriesCurrencies.filter(el=> el.iso_cod === 'EUR')
            const otherCountries = this.originCountriesCurrencies.filter(el=> el.iso_cod !== 'EUR')
            return europe.concat(otherCountries)
        }
        return this.originCountriesCurrencies
    }

    get fdestinyAmmount() {
        return formattCurrency(this.destinyAmmount)
    }

    formatInput(num) {
            let array = num.toString().split('.')
            let result =  array[0].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1\.`)
            if (array[1]){
                result=result+','+array[1].match(/\d{0,6}/);
            }
            return result
    }

    parseInput(text,oldVal) {
      if (text.match(/^[0-9\.,]+/)){
        let result = text.replace(/\./g, "").replace(',','.');
        result = result.replaceAll(',','')
        return Number(result);
      }
      else return Number(oldVal)
    }

    @Watch('writingInOriginValGlobal')
    activeWritingInOriginVal(value: boolean){
         this.writingInOriginVal = value;
    }

    @Watch('originAmmount')
    originInputChange(newval: number){
        if (this.writingInOriginVal === true){
            if (this.localRate && this.localRate.operation){
                this.destinyAmmount = currencyConvertion(this.localRate,this.originAmmount);
            }
            this.$emit('update:destinyAmmount', this.destinyAmmount);
            this.$emit('update:originAmmount', this.originAmmount);
        }
    }

    @Watch('destinyAmmount')
    destinyInputChange(newval: number){
        if (this.writingInOriginVal === false){
            if (this.localRate && this.localRate.operation){
                this.originAmmount = reverseCurrencyConvertion(this.localRate,this.destinyAmmount)
            }
            this.$emit('update:originAmmount', this.originAmmount);
            this.$emit('update:destinyAmmount', this.destinyAmmount);
        }
    }

    @Watch('rate')
    getConvertion(newVal : Rate){
        this.rateUpdate(newVal)
    }

    rateUpdate(newVal: Rate){
        this.localRate = newVal;
        if (this.localRate && this.localRate.operation){
            if (this.writingInOriginVal){
                this.destinyAmmount = currencyConvertion(newVal,this.originAmmount)
                this.$emit('update:destinyAmmount', this.destinyAmmount);
            }
            else {
                this.originAmmount = reverseCurrencyConvertion(newVal,this.destinyAmmount)
                this.$emit('update:originAmmount',this.originAmmount);
                // this.$emit('update:originAmmount', this.originAmmount);
            }
        }
    }

    writingInDestiny(){
        this.writingInOriginVal = false
        this.$emit('updateWritingInOriginVal', false);
    }

    writingInOrigin(){
        this.writingInOriginVal = true;
        this.$emit('updateWritingInOriginVal', true);
    }

    get availableDestinyCurrencies(){
        // if (this.destinyCountriesCurrencies)
        if (this.origin.countryCurrency)
            if (this.origin.countryCurrency.isoCode)
                return  this.destinyCountriesCurrencies.filter(el=> el.iso_cod !== this.origin.countryCurrency.isoCode && el.id_currency !== this.origin.countryCurrency.idCourrency)
            else return this.destinyCountriesCurrencies.filter(el=> el.id_country !== this.origin.countryCurrency.id_country && el.id_currency !== this.origin.countryCurrency.id_currency)
        else return []
    }

    selectDestinyCurrency(countryCurrency: CountryCurrency){
        this.$emit('selectDestinyCurrency',countryCurrency)
    }
    selectOriginCurrency(countryCurrency: CountryCurrency){
        if (countryCurrency.id_country === this.destiny.countryCurrency.id_country){
            this.$emit('selectOriginCurrency',countryCurrency, false);
            this.selectDestinyCurrency(this.availableDestinyCurrencies[0])
        }
        else 
            this.$emit('selectOriginCurrency',countryCurrency, true);
    }

}
